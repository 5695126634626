<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">随访频率设置</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div class="">
						<div class="ft14 ftw500 cl-info">该设置仅对未设置具体访问频率的长者生效</div>
					</div>

					<div class="mt24">
						<div class="ft14 ftw500 cl-info">按年龄段设置访问频率</div>
						<div class="mt16">
							<div class="setting-balance-sku">
								<div style="width: 25%;" class="flex space ft14 ftw500 cl-main pr10 pl20">
									<div>随访计划</div>
								</div>

								<div class="pl10 flex alcenter" style="width: 25%;">
									<div>开始年龄段</div>
								</div>

                <div class="pl10 flex alcenter" style="width: 25%;">
                  <div>结束年龄段</div>
                </div>

								<div class="pl10 flex alcenter" style="width: 25%;">
									<div>间隔天数</div>
								</div>
							</div>

							<div class="setting-balance-sku-item" v-for="(item,index) in form.taocan_detail">
								<div style="position: relative;" >
									<a-input style="width: 160px;" v-model="item.title" />
								</div>

								<div style="position: relative;margin-left: 10px;" >
									<a-input-number style="width: 130px;" v-model="item.age_bg" />
									<div class="setting-balance-sku-item-addon"></div>
								</div>

								<div style="position: relative;margin-left: 20px;">
									<a-input-number style="width:130px;"  v-model="item.age_end" />
									<div class="setting-balance-sku-item-addon"></div>
								</div>

                <div style="position: relative;margin-left: 30px;">
                  <a-input-number style="width:100px;"  v-model="item.num" />
                  <div class="setting-balance-sku-item-addon"></div>
                </div>

								<i class="iconfont iconbtn_close ft16 clickAct ml20" @click="delRechargeItem(index)"/>
							</div>

							<div class="flex end mt10" style="width: 636px;">
								<a-button icon="plus" type="primary" ghost @click="addRechargeItem()">新建</a-button>
							</div>
						</div>
					</div>

					<div class="mt30">
						<a-button type="primary" :loading="confirmLoading" @click="save()">保存</a-button>
					</div>
				</div>
			</div>

		</a-spin>
	</div>
</template>

<script>
	export default{
		components:{
		},
		data(){
			return{
				loading:false,
				confirmLoading:false,
				index:0,
				form:{
					taocan_detail:[]
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{

			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showAccessSetting').then(res=>{
					this.form=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			save(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveAccessSetting',{
					taocan_detail:JSON.stringify(this.form.taocan_detail),
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					this.confirmLoading=false;
				})
			},

			addRechargeItem() {
				this.form.taocan_detail.push({
          title:'',
          age_bg: '',
          age_end: '',
          num:'',
				});
			},
			delRechargeItem(index) {
				this.form.taocan_detail.splice(index, 1);
			},
		}
	}
</script>

<style>
	.setting-balance-addon{
		position: absolute;
		top:0;
		right:26px;
		height: 32px;
		line-height: 32px;

		font-size: 14px;
		font-weight: 500;
		color: #A6AEC2;
	}

	.setting-balance-sku {
		width: 636px;
		height: 40px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		display: flex;
		line-height: 40px;
	}


	.setting-balance-sku .ant-select-arrow {
		color: #656A75;
	}

	.setting-balance-sku .ant-select-selection {
		background-color: #F9F9F9;
		border:none;
		box-shadow: none;
	}

	.setting-balance-sku-item{
		width: 636px;
		display: flex;
		border-bottom: 1px solid #EBEDF5;
		padding: 10px 20px 10px 0px;
	}

	.setting-balance-sku-item-addon{
		position: absolute;
		height: 32px;
		line-height: 32px;
		right: 26px;
		top:0px
	}

	.setting-balance-sku-item-coupon{
		width: 200px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #EBEDF5;
		padding: 0px 16px 0px 16px;
	}
</style>
